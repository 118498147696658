// Copyright (C) Bandicoot Imaging Sciences 2021

import * as THREE from 'three';

// sRGB to XYZ matrix
const lsrgbToXyz = [
  0.4124564,  0.3575761,  0.1804375,
  0.2126729,  0.7151522,  0.0721750,
  0.0193339,  0.1191920,  0.9503041
];

// XYZ to sRGB
const xyzToLsrgb = [
   3.2404542, -1.5371385, -0.4985314,
  -0.9692660,  1.8760108,  0.0415560,
   0.0556434, -0.2040259,  1.0572252
];

// Bradford transform for chromatic adaptation
const bradford = [
   0.8951000,  0.2664000, -0.1614000,
  -0.7502000,  1.7135000,  0.0367000,
   0.0389000, -0.0685000,  1.0296000
];

// Inverse Bradford transform for chromatic adaptation
const bradfordInv = [
   0.9869929, -0.1470543, 0.1599627,
   0.4323053,  0.5183603, 0.0492912,
  -0.0085287,  0.0400428, 0.9684867
];

const cctToXyLut = [
  [0.652750056, 0.344462227],  // CCT = 1,000
  [0.65133882, 0.345710187],   // CCT = 1,010
  [0.649930068, 0.346948778],  // CCT = 1,020
  [0.648523859, 0.3481778],    // ...
  [0.647120248, 0.349397065],
  [0.645719283, 0.350606394],
  [0.644321009, 0.351805612],
  [0.642925466, 0.352994558],
  [0.64153269, 0.354173075],
  [0.640142712, 0.355341013],
  [0.638755562, 0.356498233],
  [0.637371265, 0.357644601],
  [0.635989844, 0.358779989],
  [0.634611318, 0.359904278],
  [0.633235707, 0.361017355],
  [0.631863024, 0.362119113],
  [0.630493285, 0.363209453],
  [0.629126501, 0.364288279],
  [0.627762682, 0.365355504],
  [0.626401838, 0.366411046],
  [0.625043977, 0.367454829],
  [0.623689105, 0.368486781],
  [0.622337229, 0.369506838],
  [0.620988354, 0.370514939],
  [0.619642484, 0.37151103],
  [0.618299625, 0.372495062],
  [0.616959778, 0.373466989],
  [0.615622949, 0.374426771],
  [0.61428914, 0.375374375],
  [0.612958353, 0.376309768],
  [0.611630593, 0.377232926],
  [0.610305862, 0.378143826],
  [0.608984163, 0.379042452],
  [0.607665498, 0.379928789],
  [0.606349873, 0.38080283],
  [0.605037289, 0.381664569],
  [0.603727751, 0.382514004],
  [0.602421263, 0.383351138],
  [0.601117828, 0.384175978],
  [0.599817452, 0.384988532],
  [0.59852014, 0.385788815],
  [0.597225897, 0.386576843],
  [0.595934729, 0.387352635],
  [0.594646641, 0.388116215],
  [0.593361642, 0.38886761],
  [0.592079736, 0.389606847],
  [0.590800933, 0.39033396],
  [0.589525239, 0.391048983],
  [0.588252664, 0.391751954],
  [0.586983215, 0.392442914],
  [0.585716902, 0.393121906],
  [0.584453734, 0.393788975],
  [0.583193722, 0.394444169],
  [0.581936874, 0.395087538],
  [0.580683203, 0.395719136],
  [0.579432719, 0.396339017],
  [0.578185432, 0.396947238],
  [0.576941356, 0.397543858],
  [0.575700502, 0.398128939],
  [0.574462881, 0.398702542],
  [0.573228508, 0.399264734],
  [0.571997394, 0.39981558],
  [0.570769552, 0.400355148],
  [0.569544997, 0.400883509],
  [0.568323741, 0.401400735],
  [0.567105798, 0.401906897],
  [0.565891182, 0.402402071],
  [0.564679908, 0.402886332],
  [0.563471988, 0.403359758],
  [0.562267439, 0.403822426],
  [0.561066274, 0.404274416],
  [0.559868507, 0.404715809],
  [0.558674154, 0.405146687],
  [0.557483228, 0.405567132],
  [0.556295746, 0.405977229],
  [0.55511172, 0.406377061],
  [0.553931167, 0.406766714],
  [0.5527541, 0.407146275],
  [0.551580535, 0.40751583],
  [0.550410486, 0.407875468],
  [0.549243968, 0.408225276],
  [0.548080994, 0.408565344],
  [0.54692158, 0.408895761],
  [0.54576574, 0.409216618],
  [0.544613488, 0.409528004],
  [0.543464837, 0.409830011],
  [0.542319802, 0.410122731],
  [0.541178397, 0.410406254],
  [0.540040635, 0.410680672],
  [0.538906529, 0.410946079],
  [0.537776092, 0.411202566],
  [0.536649338, 0.411450226],
  [0.53552628, 0.411689152],
  [0.534406928, 0.411919436],
  [0.533291297, 0.412141172],
  [0.532179397, 0.412354452],
  [0.531071242, 0.41255937],
  [0.529966841, 0.412756018],
  [0.528866206, 0.41294449],
  [0.527769349, 0.413124878],
  [0.52667628, 0.413297275],
  [0.525587009, 0.413461773],
  [0.524501547, 0.413618465],
  [0.523419903, 0.413767443],
  [0.522342086, 0.413908799],
  [0.521268106, 0.414042625],
  [0.520197971, 0.414169012],
  [0.519131691, 0.414288052],
  [0.518069273, 0.414399834],
  [0.517010725, 0.414504451],
  [0.515956054, 0.414601991],
  [0.514905268, 0.414692545],
  [0.513858374, 0.414776202],
  [0.512815378, 0.414853051],
  [0.511776286, 0.41492318],
  [0.510741104, 0.414986678],
  [0.509709837, 0.415043632],
  [0.508682492, 0.41509413],
  [0.507659072, 0.415138258],
  [0.506639581, 0.415176101],
  [0.505624025, 0.415207747],
  [0.504612407, 0.415233279],
  [0.503604729, 0.415252783],
  [0.502600996, 0.415266342],
  [0.50160121, 0.415274039],
  [0.500605374, 0.415275958],
  [0.499613489, 0.415272181],
  [0.498625557, 0.415262789],
  [0.497641579, 0.415247863],
  [0.496661557, 0.415227484],
  [0.495685492, 0.415201732],
  [0.494713383, 0.415170685],
  [0.493745231, 0.415134422],
  [0.492781035, 0.415093022],
  [0.491820796, 0.41504656],
  [0.490864511, 0.414995114],
  [0.48991218, 0.414938759],
  [0.488963802, 0.414877572],
  [0.488019374, 0.414811625],
  [0.487078894, 0.414740994],
  [0.48614236, 0.414665751],
  [0.485209769, 0.414585969],
  [0.484281119, 0.41450172],
  [0.483356405, 0.414413074],
  [0.482435624, 0.414320103],
  [0.481518773, 0.414222875],
  [0.480605846, 0.414121461],
  [0.47969684, 0.414015928],
  [0.47879175, 0.413906344],
  [0.477890572, 0.413792776],
  [0.476993298, 0.41367529],
  [0.476099925, 0.413553952],
  [0.475210446, 0.413428827],
  [0.474324856, 0.413299979],
  [0.473443148, 0.413167472],
  [0.472565315, 0.413031368],
  [0.471691352, 0.41289173],
  [0.47082125, 0.412748618],
  [0.469955003, 0.412602095],
  [0.469092603, 0.412452219],
  [0.468234043, 0.41229905],
  [0.467379315, 0.412142648],
  [0.46652841, 0.411983069],
  [0.465681321, 0.411820372],
  [0.464838039, 0.411654612],
  [0.463998555, 0.411485847],
  [0.46316286, 0.411314131],
  [0.462330946, 0.41113952],
  [0.461502803, 0.410962066],
  [0.460678421, 0.410781825],
  [0.459857792, 0.410598847],
  [0.459040905, 0.410413186],
  [0.45822775, 0.410224893],
  [0.457418317, 0.410034019],
  [0.456612596, 0.409840614],
  [0.455810577, 0.409644727],
  [0.455012248, 0.409446407],
  [0.4542176, 0.409245703],
  [0.453426621, 0.409042663],
  [0.452639301, 0.408837333],
  [0.451855627, 0.40862976],
  [0.45107559, 0.408419989],
  [0.450299176, 0.408208067],
  [0.449526376, 0.407994037],
  [0.448757177, 0.407777944],
  [0.447991568, 0.407559832],
  [0.447229536, 0.407339742],
  [0.446471069, 0.407117718],
  [0.445716156, 0.406893801],
  [0.444964784, 0.406668033],
  [0.444216942, 0.406440454],
  [0.443472615, 0.406211104],
  [0.442731793, 0.405980023],
  [0.441994462, 0.405747249],
  [0.441260611, 0.405512822],
  [0.440530225, 0.405276779],
  [0.439803292, 0.405039158],
  [0.439079799, 0.404799995],
  [0.438359734, 0.404559326],
  [0.437643083, 0.404317189],
  [0.436929834, 0.404073617],
  [0.436219972, 0.403828646],
  [0.435513485, 0.40358231],
  [0.434810359, 0.403334643],
  [0.434110581, 0.403085679],
  [0.433414138, 0.40283545],
  [0.432721016, 0.402583988],
  [0.432031202, 0.402331326],
  [0.431344682, 0.402077495],
  [0.430661442, 0.401822526],
  [0.429981469, 0.401566449],
  [0.429304749, 0.401309295],
  [0.428631269, 0.401051094],
  [0.427961015, 0.400791874],
  [0.427293972, 0.400531664],
  [0.426630128, 0.400270493],
  [0.425969468, 0.400008388],
  [0.425311978, 0.399745378],
  [0.424657645, 0.399481488],
  [0.424006455, 0.399216747],
  [0.423358394, 0.398951179],
  [0.422713447, 0.398684812],
  [0.422071602, 0.39841767],
  [0.421432844, 0.398149778],
  [0.420797159, 0.397881162],
  [0.420164533, 0.397611845],
  [0.419534952, 0.397341851],
  [0.418908402, 0.397071205],
  [0.41828487, 0.396799929],
  [0.417664341, 0.396528045],
  [0.417046802, 0.396255578],
  [0.416432238, 0.395982547],
  [0.415820635, 0.395708977],
  [0.41521198, 0.395434887],
  [0.414606259, 0.395160299],
  [0.414003457, 0.394885233],
  [0.413403561, 0.394609711],
  [0.412806557, 0.394333752],
  [0.412212431, 0.394057375],
  [0.411621169, 0.393780601],
  [0.411032757, 0.393503449],
  [0.410447182, 0.393225937],
  [0.409864429, 0.392948084],
  [0.409284485, 0.392669907],
  [0.408707337, 0.392391426],
  [0.408132969, 0.392112657],
  [0.40756137, 0.391833618],
  [0.406992524, 0.391554326],
  [0.406426418, 0.391274797],
  [0.405863039, 0.390995049],
  [0.405302374, 0.390715097],
  [0.404744407, 0.390434958],
  [0.404189127, 0.390154646],
  [0.403636518, 0.389874177],
  [0.403086569, 0.389593568],
  [0.402539265, 0.389312831],
  [0.401994593, 0.389031982],
  [0.40145254, 0.388751036],
  [0.400913092, 0.388470006],
  [0.400376237, 0.388188907],
  [0.39984196, 0.387907751],
  [0.399310248, 0.387626553],
  [0.398781089, 0.387345326],
  [0.398254468, 0.387064082],
  [0.397730374, 0.386782834],
  [0.397208793, 0.386501596],
  [0.396689712, 0.386220378],
  [0.396173118, 0.385939193],
  [0.395658998, 0.385658053],
  [0.395147339, 0.38537697],
  [0.394638128, 0.385095955],
  [0.394131353, 0.384815019],
  [0.393627, 0.384534173],
  [0.393125058, 0.384253428],
  [0.392625512, 0.383972795],
  [0.392128352, 0.383692284],
  [0.391633563, 0.383411906],
  [0.391141134, 0.38313167],
  [0.390651052, 0.382851586],
  [0.390163305, 0.382571664],
  [0.38967788, 0.382291914],
  [0.389194765, 0.382012344],
  [0.388713948, 0.381732964],
  [0.388235416, 0.381453784],
  [0.387759158, 0.381174811],
  [0.38728516, 0.380896054],
  [0.386813412, 0.380617522],
  [0.3863439, 0.380339224],
  [0.385876613, 0.380061167],
  [0.38541154, 0.379783359],
  [0.384948667, 0.379505808],
  [0.384487984, 0.379228523],
  [0.384029479, 0.37895151],
  [0.383573139, 0.378674776],
  [0.383118953, 0.37839833],
  [0.38266691, 0.378122178],
  [0.382216998, 0.377846327],
  [0.381769205, 0.377570784],
  [0.381323521, 0.377295556],
  [0.380879932, 0.377020649],
  [0.380438429, 0.37674607],
  [0.379999, 0.376471825],
  [0.379561634, 0.376197919],
  [0.379126319, 0.37592436],
  [0.378693044, 0.375651153],
  [0.378261799, 0.375378304],
  [0.377832571, 0.375105819],
  [0.377405351, 0.374833702],
  [0.376980127, 0.37456196],
  [0.376556888, 0.374290598],
  [0.376135624, 0.37401962],
  [0.375716324, 0.373749033],
  [0.375298977, 0.373478841],
  [0.374883572, 0.373209049],
  [0.374470099, 0.372939661],
  [0.374058547, 0.372670683],
  [0.373648906, 0.372402119],
  [0.373241165, 0.372133972],
  [0.372835314, 0.371866249],
  [0.372431342, 0.371598953],
  [0.37202924, 0.371332087],
  [0.371628997, 0.371065657],
  [0.371230602, 0.370799666],
  [0.370834046, 0.370534117],
  [0.370439319, 0.370269016],
  [0.37004641, 0.370004364],
  [0.36965531, 0.369740167],
  [0.369266009, 0.369476427],
  [0.368878496, 0.369213148],
  [0.368492762, 0.368950334],
  [0.368108798, 0.368687987],
  [0.367726593, 0.36842611],
  [0.367346138, 0.368164708],
  [0.366967423, 0.367903782],
  [0.366590439, 0.367643335],
  [0.366215176, 0.367383372],
  [0.365841625, 0.367123893],
  [0.365469776, 0.366864903],
  [0.36509962, 0.366606404],
  [0.364731148, 0.366348397],
  [0.364364351, 0.366090887],
  [0.363999218, 0.365833874],
  [0.363635742, 0.365577362],
  [0.363273913, 0.365321353],
  [0.362913721, 0.365065849],
  [0.362555159, 0.364810852],
  [0.362198217, 0.364556364],
  [0.361842886, 0.364302387],
  [0.361489157, 0.364048924],
  [0.361137022, 0.363795976],
  [0.360786471, 0.363543545],
  [0.360437497, 0.363291632],
  [0.36009009, 0.36304024],
  [0.359744242, 0.362789371],
  [0.359399944, 0.362539024],
  [0.359057188, 0.362289204],
  [0.358715965, 0.36203991],
  [0.358376267, 0.361791144],
  [0.358038086, 0.361542908],
  [0.357701413, 0.361295203],
  [0.35736624, 0.361048031],
  [0.357032559, 0.360801391],
  [0.356700361, 0.360555287],
  [0.356369639, 0.360309718],
  [0.356040385, 0.360064687],
  [0.35571259, 0.359820193],
  [0.355386246, 0.359576239],
  [0.355061346, 0.359332824],
  [0.354737882, 0.359089951],
  [0.354415845, 0.358847619],
  [0.354095228, 0.35860583],
  [0.353776024, 0.358364584],
  [0.353458224, 0.358123882],
  [0.353141821, 0.357883725],
  [0.352826807, 0.357644114],
  [0.352513175, 0.357405048],
  [0.352200917, 0.35716653],
  [0.351890025, 0.356928559],
  [0.351580493, 0.356691135],
  [0.351272313, 0.35645426],
  [0.350965477, 0.356217933],
  [0.350659979, 0.355982156],
  [0.35035581, 0.355746928],
  [0.350052964, 0.355512249],
  [0.349751434, 0.355278121],
  [0.349451212, 0.355044543],
  [0.349152291, 0.354811516],
  [0.348854665, 0.35457904],
  [0.348558325, 0.354347114],
  [0.348263266, 0.35411574],
  [0.347969481, 0.353884917],
  [0.347676961, 0.353654645],
  [0.347385702, 0.353424924],
  [0.347095695, 0.353195755],
  [0.346806934, 0.352967137],
  [0.346519412, 0.352739071],
  [0.346233123, 0.352511556],
  [0.34594806, 0.352284592],
  [0.345664216, 0.352058179],
  [0.345381585, 0.351832317],
  [0.345100161, 0.351607005],
  [0.344819936, 0.351382245],
  [0.344540904, 0.351158034],
  [0.344263059, 0.350934374],
  [0.343986395, 0.350711264],
  [0.343710905, 0.350488703],
  [0.343436583, 0.350266692],
  [0.343163422, 0.35004523],
  [0.342891417, 0.349824316],
  [0.342620561, 0.34960395],
  [0.342350848, 0.349384133],
  [0.342082272, 0.349164863],
  [0.341814827, 0.348946139],
  [0.341548507, 0.348727963],
  [0.341283305, 0.348510332],
  [0.341019217, 0.348293247],
  [0.340756235, 0.348076708],
  [0.340494354, 0.347860713],
  [0.340233569, 0.347645261],
  [0.339973873, 0.347430354],
  [0.33971526, 0.347215989],
  [0.339457726, 0.347002167],
  [0.339201263, 0.346788886],
  [0.338945867, 0.346576146],
  [0.338691531, 0.346363947],
  [0.338438251, 0.346152288],
  [0.33818602, 0.345941168],
  [0.337934833, 0.345730586],
  [0.337684684, 0.345520542],
  [0.337435569, 0.345311035],
  [0.337187481, 0.345102064],
  [0.336940415, 0.344893629],
  [0.336694366, 0.344685728],
  [0.336449328, 0.344478362],
  [0.336205296, 0.344271529],
  [0.335962265, 0.344065228],
  [0.335720229, 0.343859459],
  [0.335479184, 0.34365422],
  [0.335239124, 0.343449512],
  [0.335000043, 0.343245333],
  [0.334761938, 0.343041682],
  [0.334524802, 0.342838558],
  [0.33428863, 0.342635962],
  [0.334053418, 0.34243389],
  [0.333819161, 0.342232344],
  [0.333585853, 0.342031321],
  [0.33335349, 0.341830822],
  [0.333122066, 0.341630844],
  [0.332891577, 0.341431387],
  [0.332662018, 0.341232451],
  [0.332433384, 0.341034034],
  [0.332205671, 0.340836135],
  [0.331978873, 0.340638753],
  [0.331752985, 0.340441888],
  [0.331528004, 0.340245538],
  [0.331303924, 0.340049702],
  [0.33108074, 0.33985438],
  [0.330858449, 0.33965957],
  [0.330637045, 0.339465271],
  [0.330416524, 0.339271482],
  [0.330196881, 0.339078203],
  [0.329978111, 0.338885432],
  [0.329760212, 0.338693168],
  [0.329543176, 0.338501411],
  [0.329327002, 0.338310158],
  [0.329111683, 0.338119409],
  [0.328897215, 0.337929163],
  [0.328683595, 0.337739419],
  [0.328470818, 0.337550176],
  [0.328258879, 0.337361433],
  [0.328047774, 0.337173188],
  [0.327837499, 0.336985441],
  [0.32762805, 0.33679819],
  [0.327419423, 0.336611435],
  [0.327211612, 0.336425173],
  [0.327004615, 0.336239405],
  [0.326798427, 0.336054129],
  [0.326593044, 0.335869343],
  [0.326388461, 0.335685048],
  [0.326184675, 0.335501241],
  [0.325981682, 0.335317921],
  [0.325779478, 0.335135088],
  [0.325578058, 0.33495274],
  [0.325377419, 0.334770876],
  [0.325177557, 0.334589495],
  [0.324978468, 0.334408596],
  [0.324780147, 0.334228177],
  [0.324582592, 0.334048238],
  [0.324385798, 0.333868777],
  [0.324189761, 0.333689793],
  [0.323994478, 0.333511285],
  [0.323799944, 0.333333252],
  [0.323606157, 0.333155692],
  [0.323413112, 0.332978605],
  [0.323220805, 0.33280199],
  [0.323029233, 0.332625844],
  [0.322838393, 0.332450167],
  [0.32264828, 0.332274958],
  [0.322458891, 0.332100216],
  [0.322270222, 0.331925939],
  [0.32208227, 0.331752126],
  [0.321895031, 0.331578777],
  [0.321708502, 0.331405889],
  [0.321522678, 0.331233461],
  [0.321337558, 0.331061493],
  [0.321153136, 0.330889984],
  [0.32096941, 0.330718931],
  [0.320786376, 0.330548335],
  [0.320604031, 0.330378193],
  [0.320422371, 0.330208504],
  [0.320241393, 0.330039268],
  [0.320061094, 0.329870483],
  [0.31988147, 0.329702148],
  [0.319702518, 0.329534261],
  [0.319524234, 0.329366822],
  [0.319346616, 0.329199829],
  [0.31916966, 0.329033282],
  [0.318993363, 0.328867178],
  [0.318817721, 0.328701517],
  [0.318642732, 0.328536298],
  [0.318468391, 0.328371519],
  [0.318294697, 0.328207178],
  [0.318121646, 0.328043276],
  [0.317949234, 0.327879811],
  [0.317777459, 0.327716781],
  [0.317606317, 0.327554185],
  [0.317435806, 0.327392023],
  [0.317265922, 0.327230292],
  [0.317096663, 0.327068992],
  [0.316928025, 0.326908122],
  [0.316760005, 0.32674768],
  [0.3165926, 0.326587665],
  [0.316425807, 0.326428076],
  [0.316259624, 0.326268912],
  [0.316094047, 0.326110171],
  [0.315929074, 0.325951853],
  [0.315764701, 0.325793956],
  [0.315600926, 0.325636479],
  [0.315437746, 0.32547942],
  [0.315275157, 0.32532278],
  [0.315113158, 0.325166555],
  [0.314951745, 0.325010746],
  [0.314790915, 0.324855351],
  [0.314630666, 0.324700369],
  [0.314470995, 0.324545798],
  [0.314311899, 0.324391638],
  [0.314153375, 0.324237887],
  [0.313995421, 0.324084545],
  [0.313838034, 0.323931609],
  [0.313681211, 0.323779079],
  [0.313524949, 0.323626954],
  [0.313369247, 0.323475232],
  [0.3132141, 0.323323913],
  [0.313059507, 0.323172994],
  [0.312905466, 0.323022475],
  [0.312751972, 0.322872356],
  [0.312599024, 0.322722633],
  [0.31244662, 0.322573308],
  [0.312294755, 0.322424377],
  [0.312143429, 0.322275841],
  [0.311992639, 0.322127697],
  [0.311842381, 0.321979945],
  [0.311692654, 0.321832584],
  [0.311543455, 0.321685613],
  [0.311394782, 0.32153903],
  [0.311246631, 0.321392833],
  [0.311099001, 0.321247023],
  [0.31095189, 0.321101598],
  [0.310805294, 0.320956557],
  [0.310659211, 0.320811898],
  [0.310513639, 0.320667621],
  [0.310368576, 0.320523724],
  [0.310224019, 0.320380206],
  [0.310079966, 0.320237066],
  [0.309936414, 0.320094303],
  [0.309793362, 0.319951916],
  [0.309650806, 0.319809904],
  [0.309508745, 0.319668265],
  [0.309367176, 0.319526999],
  [0.309226097, 0.319386104],
  [0.309085506, 0.319245579],
  [0.3089454, 0.319105424],
  [0.308805777, 0.318965636],
  [0.308666636, 0.318826215],
  [0.308527973, 0.31868716],
  [0.308389786, 0.318548469],
  [0.308252075, 0.318410142],
  [0.308114835, 0.318272178],
  [0.307978065, 0.318134575],
  [0.307841763, 0.317997332],
  [0.307705927, 0.317860448],
  [0.307570555, 0.317723922],
  [0.307435644, 0.317587754],
  [0.307301192, 0.317451941],
  [0.307167198, 0.317316483],
  [0.307033659, 0.317181379],
  [0.306900573, 0.317046627],
  [0.306767938, 0.316912227],
  [0.306635752, 0.316778178],
  [0.306504013, 0.316644477],
  [0.306372719, 0.316511126],
  [0.306241868, 0.316378121],
  [0.306111458, 0.316245463],
  [0.305981486, 0.31611315],
  [0.305851952, 0.315981181],
  [0.305722853, 0.315849555],
  [0.305594187, 0.315718271],
  [0.305465952, 0.315587328],
  [0.305338146, 0.315456725],
  [0.305210767, 0.315326461],
  [0.305083813, 0.315196535],
  [0.304957283, 0.315066946],
  [0.304831175, 0.314937692],
  [0.304705485, 0.314808773],
  [0.304580214, 0.314680187],
  [0.304455359, 0.314551935],
  [0.304330917, 0.314424013],
  [0.304206888, 0.314296423],
  [0.304083269, 0.314169162],
  [0.303960058, 0.31404223],
  [0.303837255, 0.313915625],
  [0.303714856, 0.313789346],
  [0.30359286, 0.313663394],
  [0.303471265, 0.313537765],
  [0.30335007, 0.313412461],
  [0.303229273, 0.313287478],
  [0.303108871, 0.313162818],
  [0.302988864, 0.313038478],
  [0.302869249, 0.312914457],
  [0.302750025, 0.312790755],
  [0.302631191, 0.31266737],
  [0.302512743, 0.312544302],
  [0.302394681, 0.31242155],
  [0.302277003, 0.312299112],
  [0.302159707, 0.312176987],
  [0.302042792, 0.312055176],
  [0.301926256, 0.311933676],
  [0.301810097, 0.311812487],
  [0.301694313, 0.311691607],
  [0.301578904, 0.311571036],
  [0.301463867, 0.311450773],
  [0.3013492, 0.311330817],
  [0.301234903, 0.311211167],
  [0.301120973, 0.311091822],
  [0.301007409, 0.310972781],
  [0.300894209, 0.310854043],
  [0.300781372, 0.310735607],
  [0.300668897, 0.310617472],
  [0.30055678, 0.310499637],
  [0.300445022, 0.310382102],
  [0.300333621, 0.310264865],
  [0.300222574, 0.310147925],
  [0.300111881, 0.310031282],
  [0.300001539, 0.309914935],
  [0.299891548, 0.309798882],
  [0.299781906, 0.309683123],
  [0.299672611, 0.309567657],
  [0.299563662, 0.309452483],
  [0.299455058, 0.309337599],
  [0.299346796, 0.309223006],
  [0.299238876, 0.309108702],
  [0.299131296, 0.308994686],
  [0.299024055, 0.308880958],
  [0.29891715, 0.308767516],
  [0.298810582, 0.30865436],
  [0.298704347, 0.308541488],
  [0.298598446, 0.308428901],
  [0.298492875, 0.308316596],
  [0.298387635, 0.308204573],
  [0.298282723, 0.308092831],
  [0.298178139, 0.30798137],
  [0.29807388, 0.307870188],
  [0.297969946, 0.307759285],
  [0.297866335, 0.307648659],
  [0.297763046, 0.30753831],
  [0.297660077, 0.307428237],
  [0.297557427, 0.307318439],
  [0.297455095, 0.307208916],
  [0.297353079, 0.307099666],
  [0.297251378, 0.306990688],
  [0.297149991, 0.306881982],
  [0.297048916, 0.306773546],
  [0.296948152, 0.306665381],
  [0.296847698, 0.306557485],
  [0.296747552, 0.306449857],
  [0.296647714, 0.306342497],
  [0.296548181, 0.306235403],
  [0.296448953, 0.306128575],
  [0.296350029, 0.306022012],
  [0.296251406, 0.305915713],
  [0.296153085, 0.305809677],
  [0.296055062, 0.305703904],
  [0.295957339, 0.305598392],
  [0.295859912, 0.305493142],
  [0.295762781, 0.305388151],
  [0.295665945, 0.30528342],
  [0.295569403, 0.305178947],
  [0.295473152, 0.305074731],
  [0.295377193, 0.304970773],
  [0.295281523, 0.30486707],
  [0.295186142, 0.304763623],
  [0.295091049, 0.30466043],
  [0.294996242, 0.30455749],
  [0.29490172, 0.304454804],
  [0.294807481, 0.304352369],
  [0.294713526, 0.304250186],
  [0.294619852, 0.304148254],
  [0.294526459, 0.304046571],
  [0.294433345, 0.303945136],
  [0.294340509, 0.30384395],
  [0.29424795, 0.303743012],
  [0.294155667, 0.30364232],
  [0.294063658, 0.303541874],
  [0.293971924, 0.303441673],
  [0.293880462, 0.303341716],
  [0.293789271, 0.303242003],
  [0.293698351, 0.303142533],
  [0.2936077, 0.303043304],
  [0.293517317, 0.302944317],
  [0.293427202, 0.302845571],
  [0.293337352, 0.302747064],
  [0.293247767, 0.302648796],
  [0.293158446, 0.302550767],
  [0.293069388, 0.302452975],
  [0.292980591, 0.30235542],
  [0.292892056, 0.302258101],
  [0.29280378, 0.302161018],
  [0.292715762, 0.302064169],
  [0.292628002, 0.301967554],
  [0.292540499, 0.301871173],
  [0.292453251, 0.301775023],
  [0.292366258, 0.301679106],
  [0.292279518, 0.301583419],
  [0.29219303, 0.301487963],
  [0.292106794, 0.301392736],
  [0.292020809, 0.301297739],
  [0.291935073, 0.301202969],
  [0.291849585, 0.301108427],
  [0.291764345, 0.301014112],
  [0.291679351, 0.300920023],
  [0.291594603, 0.300826159],
  [0.291510099, 0.30073252],
  [0.291425839, 0.300639105],
  [0.291341822, 0.300545913],
  [0.291258046, 0.300452944],
  [0.291174511, 0.300360196],
  [0.291091216, 0.30026767],
  [0.29100816, 0.300175365],
  [0.290925341, 0.300083279],
  [0.29084276, 0.299991413],
  [0.290760414, 0.299899765],
  [0.290678304, 0.299808334],
  [0.290596427, 0.299717122],
  [0.290514784, 0.299626125],
  [0.290433374, 0.299535345],
  [0.290352194, 0.299444779],
  [0.290271246, 0.299354428],
  [0.290190527, 0.299264291],
  [0.290110036, 0.299174368],
  [0.290029774, 0.299084656],
  [0.289949738, 0.298995157],
  [0.289869929, 0.298905869],
  [0.289790345, 0.298816791],
  [0.289710985, 0.298727924],
  [0.289631848, 0.298639265],
  [0.289552934, 0.298550816],
  [0.289474242, 0.298462574],
  [0.289395771, 0.29837454],
  [0.28931752, 0.298286713],
  [0.289239488, 0.298199091],
  [0.289161674, 0.298111676],
  [0.289084078, 0.298024465],
  [0.289006699, 0.297937458],
  [0.288929535, 0.297850655],
  [0.288852586, 0.297764055],
  [0.288775852, 0.297677657],
  [0.288699331, 0.297591461],
  [0.288623022, 0.297505467],
  [0.288546925, 0.297419672],
  [0.288471039, 0.297334078],
  [0.288395363, 0.297248683],
  [0.288319897, 0.297163487],
  [0.288244639, 0.297078488],
  [0.288169589, 0.296993688],
  [0.288094745, 0.296909084],
  [0.288020108, 0.296824676],
  [0.287945676, 0.296740464],
  [0.287871449, 0.296656448],
  [0.287797426, 0.296572625],
  [0.287723606, 0.296488997],
  [0.287649987, 0.296405562],
  [0.287576571, 0.29632232],
  [0.287503355, 0.29623927],
  [0.287430339, 0.296156411],
  [0.287357523, 0.296073744],
  [0.287284905, 0.295991266],
  [0.287212484, 0.295908979],
  [0.287140261, 0.295826881],
  [0.287068234, 0.295744972],
  [0.286996402, 0.295663251],
  [0.286924766, 0.295581717],
  [0.286853323, 0.295500371],
  [0.286782074, 0.29541921],
  [0.286711017, 0.295338236],
  [0.286640152, 0.295257447],
  [0.286569479, 0.295176843],
  [0.286498996, 0.295096423],
  [0.286428702, 0.295016186],
  [0.286358598, 0.294936133],
  [0.286288682, 0.294856262],
  [0.286218954, 0.294776573],
  [0.286149412, 0.294697066],
  [0.286080057, 0.294617739],
  [0.286010888, 0.294538593],
  [0.285941903, 0.294459627],
  [0.285873103, 0.294380839],
  [0.285804486, 0.294302231],
  [0.285736052, 0.294223801],
  [0.285667801, 0.294145548],
  [0.28559973, 0.294067473],
  [0.285531841, 0.293989574],
  [0.285464132, 0.293911851],
  [0.285396602, 0.293834304],
  [0.285329251, 0.293756931],
  [0.285262079, 0.293679734],
  [0.285195084, 0.29360271],
  [0.285128266, 0.29352586],
  [0.285061624, 0.293449182],
  [0.284995157, 0.293372677],
  [0.284928866, 0.293296344],
  [0.284862749, 0.293220182],
  [0.284796806, 0.293144191],
  [0.284731036, 0.293068371],
  [0.284665438, 0.29299272],
  [0.284600012, 0.292917239],
  [0.284534757, 0.292841927],
  [0.284469673, 0.292766782],
  [0.284404759, 0.292691806],
  [0.284340014, 0.292616997],
  [0.284275438, 0.292542355],
  [0.28421103, 0.29246788],
  [0.28414679, 0.29239357],
  [0.284082716, 0.292319425],
  [0.284018809, 0.292245446],
  [0.283955067, 0.292171631],
  [0.283891491, 0.292097979],
  [0.283828079, 0.292024492],
  [0.283764831, 0.291951167],
  [0.283701747, 0.291878004],
  [0.283638825, 0.291805004],
  [0.283576065, 0.291732165],
  [0.283513467, 0.291659487],
  [0.28345103, 0.29158697],
  [0.283388753, 0.291514612],
  [0.283326637, 0.291442415],
  [0.283264679, 0.291370376],
  [0.283202881, 0.291298496],
  [0.28314124, 0.291226774],
  [0.283079757, 0.29115521],
  [0.283018432, 0.291083804],
  [0.282957262, 0.291012554],
  [0.282896249, 0.29094146],
  [0.282835391, 0.290870523],
  [0.282774688, 0.29079974],
  [0.282714139, 0.290729113],
  [0.282653744, 0.290658641],
  [0.282593502, 0.290588322],
  [0.282533413, 0.290518157],
  [0.282473476, 0.290448145],
  [0.28241369, 0.290378286],
  [0.282354056, 0.290308579],
  [0.282294572, 0.290239024],
  [0.282235238, 0.29016962],
  [0.282176054, 0.290100368],
  [0.282117018, 0.290031265],
  [0.282058131, 0.289962313],
  [0.281999392, 0.289893511],
  [0.281940801, 0.289824858],
  [0.281882356, 0.289756353],
  [0.281824057, 0.289687997],
  [0.281765905, 0.289619789],
  [0.281707898, 0.289551728],
  [0.281650035, 0.289483814],
  [0.281592317, 0.289416047],
  [0.281534743, 0.289348426],
  [0.281477312, 0.28928095],
  [0.281420024, 0.289213621],
  [0.281362879, 0.289146436],
  [0.281305875, 0.289079395],
  [0.281249013, 0.289012499],
  [0.281192291, 0.288945746],
  [0.28113571, 0.288879137],
  [0.281079269, 0.28881267],
  [0.281022967, 0.288746346],
  [0.280966805, 0.288680164],
  [0.28091078, 0.288614123],
  [0.280854894, 0.288548224],
  [0.280799145, 0.288482465],
  [0.280743534, 0.288416847],
  [0.280688059, 0.288351368],
  [0.28063272, 0.28828603],
  [0.280577516, 0.28822083],
  [0.280522448, 0.28815577],
  [0.280467515, 0.288090847],
  [0.280412716, 0.288026063],
  [0.280358051, 0.287961416],
  [0.280303519, 0.287896907],
  [0.28024912, 0.287832534],
  [0.280194853, 0.287768297],
  [0.280140719, 0.287704197],
  [0.280086716, 0.287640232],
  [0.280032844, 0.287576403],
  [0.279979102, 0.287512708],
  [0.279925491, 0.287449148],
  [0.27987201, 0.287385722],
  [0.279818658, 0.28732243],
  [0.279765435, 0.287259271],
  [0.279712341, 0.287196245],
  [0.279659375, 0.287133351],
  [0.279606536, 0.28707059],
  [0.279553824, 0.28700796],
  [0.279501239, 0.286945462],
  [0.279448781, 0.286883095],
  [0.279396448, 0.286820859],
  [0.279344241, 0.286758753],
  [0.279292159, 0.286696776],
  [0.279240201, 0.28663493],
  [0.279188368, 0.286573213],
  [0.279136659, 0.286511624],
  [0.279085073, 0.286450164],
  [0.27903361, 0.286388832],
  [0.278982269, 0.286327628],
  [0.278931051, 0.286266552],
  [0.278879955, 0.286205602],
  [0.27882898, 0.286144779],
  [0.278778126, 0.286084082],
  [0.278727392, 0.286023512],
  [0.278676779, 0.285963067],
  [0.278626285, 0.285902747],
  [0.278575911, 0.285842552],
  [0.278525655, 0.285782482],
  [0.278475519, 0.285722535],
  [0.2784255, 0.285662713],
  [0.278375599, 0.285603014],
  [0.278325816, 0.285543439],
  [0.27827615, 0.285483986],
  [0.2782266, 0.285424656],
  [0.278177166, 0.285365447],
  [0.278127849, 0.285306361],
  [0.278078647, 0.285247396],
  [0.27802956, 0.285188552],
  [0.277980588, 0.285129829],
  [0.27793173, 0.285071226],
  [0.277882986, 0.285012744],
  [0.277834356, 0.284954381],
  [0.277785839, 0.284896138],
  [0.277737435, 0.284838013],
  [0.277689143, 0.284780008],
  [0.277640964, 0.284722121],
  [0.277592896, 0.284664352],
  [0.27754494, 0.284606701],
  [0.277497095, 0.284549167],
  [0.27744936, 0.28449175],
  [0.277401736, 0.28443445],
  [0.277354222, 0.284377267],
  [0.277306817, 0.2843202],
  [0.277259522, 0.284263248],
  [0.277212336, 0.284206412],
  [0.277165258, 0.284149692],
  [0.277118289, 0.284093086],
  [0.277071427, 0.284036594],
  [0.277024673, 0.283980217],
  [0.276978026, 0.283923954],
  [0.276931486, 0.283867805],
  [0.276885052, 0.283811768],
  [0.276838724, 0.283755845],
  [0.276792503, 0.283700035],
  [0.276746386, 0.283644336],
  [0.276700375, 0.28358875],
  [0.276654469, 0.283533276],
  [0.276608667, 0.283477913],
  [0.276562969, 0.283422661],
  [0.276517375, 0.28336752],
  [0.276471885, 0.283312489],
  [0.276426497, 0.283257569],
  [0.276381213, 0.283202759],
  [0.276336031, 0.283148058],
  [0.276290951, 0.283093466],
  [0.276245973, 0.283038984],
  [0.276201096, 0.28298461],
  [0.276156321, 0.282930345],
  [0.276111646, 0.282876188],
  [0.276067072, 0.282822138],
  [0.276022598, 0.282768196],
  [0.275978225, 0.282714362],
  [0.27593395, 0.282660634],
  [0.275889775, 0.282607013],
  [0.275845699, 0.282553498],
  [0.275801722, 0.28250009],
  [0.275757843, 0.282446787],
  [0.275714062, 0.28239359],
  [0.275670379, 0.282340498],
  [0.275626793, 0.282287511],
  [0.275583304, 0.282234628],
  [0.275539913, 0.28218185],
  [0.275496617, 0.282129176],
  [0.275453418, 0.282076606],
  [0.275410315, 0.282024139],
  [0.275367307, 0.281971776],
  [0.275324395, 0.281919516],
  [0.275281578, 0.281867358],
  [0.275238855, 0.281815302],
  [0.275196227, 0.281763349],
  [0.275153693, 0.281711498],
  [0.275111253, 0.281659748],
  [0.275068906, 0.2816081],
  [0.275026653, 0.281556552],
  [0.274984493, 0.281505105],
  [0.274942425, 0.281453759],
  [0.27490045, 0.281402513],
  [0.274858567, 0.281351367],
  [0.274816775, 0.281300321],
  [0.274775076, 0.281249374],
  [0.274733467, 0.281198526],
  [0.27469195, 0.281147777],
  [0.274650523, 0.281097127],
  [0.274609186, 0.281046575],
  [0.27456794, 0.280996121],
  [0.274526784, 0.280945765],
  [0.274485717, 0.280895507],
  [0.274444739, 0.280845346],
  [0.274403851, 0.280795282],
  [0.274363051, 0.280745315],
  [0.27432234, 0.280695444],
  [0.274281717, 0.28064567],
  [0.274241182, 0.280595991],
  [0.274200735, 0.280546409],
  [0.274160375, 0.280496922],
  [0.274120103, 0.28044753],
  [0.274079917, 0.280398233],
  [0.274039818, 0.280349031],
  [0.273999805, 0.280299923],
  [0.273959878, 0.28025091],
  [0.273920037, 0.280201991],
  [0.273880282, 0.280153165],
  [0.273840612, 0.280104433],
  [0.273801027, 0.280055795],
  [0.273761527, 0.280007249],
  [0.273722112, 0.279958796],
  [0.273682781, 0.279910436],
  [0.273643534, 0.279862167],
  [0.27360437, 0.279813991],
  [0.27356529, 0.279765907],
  [0.273526294, 0.279717914],
  [0.273487381, 0.279670013],
  [0.27344855, 0.279622203],
  [0.273409802, 0.279574483],
  [0.273371136, 0.279526854],
  [0.273332552, 0.279479316],
  [0.27329405, 0.279431867],
  [0.27325563, 0.279384509],
  [0.273217291, 0.27933724],
  [0.273179033, 0.27929006],
  [0.273140855, 0.27924297],
  [0.273102759, 0.279195969],
  [0.273064743, 0.279149056],
  [0.273026806, 0.279102232],
  [0.27298895, 0.279055496],
  [0.272951174, 0.279008849],
  [0.272913476, 0.278962289],
  [0.272875858, 0.278915816],
  [0.272838319, 0.278869431],
  [0.272800859, 0.278823133],
  [0.272763477, 0.278776922],
  [0.272726173, 0.278730798],
  [0.272688947, 0.27868476],
  [0.272651799, 0.278638808],
  [0.272614729, 0.278592942],
  [0.272577735, 0.278547162],
  [0.272540819, 0.278501468],
  [0.27250398, 0.278455859],
  [0.272467218, 0.278410335],
  [0.272430531, 0.278364895],
  [0.272393921, 0.278319541],
  [0.272357387, 0.278274271],
  [0.272320929, 0.278229085],
  [0.272284546, 0.278183983],
  [0.272248239, 0.278138965],
  [0.272212006, 0.278094031],
  [0.272175849, 0.27804918],
  [0.272139766, 0.278004412],
  [0.272103757, 0.277959727],
  [0.272067823, 0.277915125],
  [0.272031963, 0.277870605],
  [0.271996176, 0.277826168],
  [0.271960463, 0.277781812],
  [0.271924824, 0.277737539],
  [0.271889257, 0.277693347],
  [0.271853763, 0.277649237],
  [0.271818343, 0.277605208],
  [0.271782994, 0.27756126],
  [0.271747718, 0.277517393],
  [0.271712514, 0.277473606],
  [0.271677382, 0.2774299],
  [0.271642321, 0.277386274],
  [0.271607332, 0.277342728],
  [0.271572414, 0.277299262],
  [0.271537567, 0.277255876],
  [0.271502791, 0.277212568],
  [0.271468086, 0.277169341],
  [0.271433451, 0.277126192],
  [0.271398887, 0.277083122],
  [0.271364392, 0.27704013],
  [0.271329967, 0.276997217],
  [0.271295612, 0.276954382],
  [0.271261326, 0.276911625],
  [0.27122711, 0.276868946],
  [0.271192962, 0.276826345],
  [0.271158883, 0.276783821],
  [0.271124873, 0.276741374],
  [0.271090932, 0.276699004],
  [0.271057058, 0.276656711],
  [0.271023253, 0.276614495],
  [0.270989516, 0.276572355],
  [0.270955846, 0.276530292],
  [0.270922243, 0.276488304],
  [0.270888708, 0.276446393],
  [0.270855241, 0.276404557],
  [0.270821839, 0.276362796],
  [0.270788505, 0.276321111],
  [0.270755237, 0.276279501],
  [0.270722036, 0.276237966],
  [0.270688901, 0.276196505],
  [0.270655831, 0.276155119],
  [0.270622828, 0.276113808],
  [0.27058989, 0.27607257],
  [0.270557017, 0.276031407],
  [0.27052421, 0.275990317],
  [0.270491467, 0.275949301],
  [0.27045879, 0.275908359],
  [0.270426177, 0.27586749],
  [0.270393629, 0.275826693],
  [0.270361145, 0.27578597],
  [0.270328725, 0.275745319],
  [0.270296369, 0.275704741],
  [0.270264077, 0.275664236],
  [0.270231848, 0.275623802],
  [0.270199683, 0.27558344],
  [0.270167581, 0.275543151],
  [0.270135542, 0.275502932],
  [0.270103566, 0.275462786],
  [0.270071653, 0.27542271],
  [0.270039802, 0.275382706],
  [0.270008014, 0.275342772],
  [0.269976288, 0.27530291],
  [0.269944623, 0.275263118],
  [0.269913021, 0.275223396],
  [0.269881481, 0.275183744],
  [0.269850001, 0.275144163],
  [0.269818584, 0.275104651],
  [0.269787227, 0.27506521],
  [0.269755931, 0.275025837],
  [0.269724697, 0.274986534],
  [0.269693522, 0.2749473],
  [0.269662409, 0.274908136],
  [0.269631355, 0.27486904],
  [0.269600362, 0.274830013],
  [0.269569429, 0.274791054],
  [0.269538556, 0.274752163],
  [0.269507742, 0.274713341],
  [0.269476988, 0.274674587],
  [0.269446293, 0.274635901],
  [0.269415657, 0.274597282],
  [0.269385081, 0.274558731],
  [0.269354563, 0.274520247],
  [0.269324104, 0.274481831],
  [0.269293703, 0.274443481],
  [0.269263361, 0.274405198],
  [0.269233077, 0.274366982],
  [0.269202851, 0.274328833],
  [0.269172683, 0.274290749],
  [0.269142573, 0.274252733],
  [0.26911252, 0.274214782],
  [0.269082525, 0.274176897],
  [0.269052587, 0.274139077],
  [0.269022706, 0.274101324],
  [0.268992882, 0.274063635],
  [0.268963114, 0.274026012],
  [0.268933404, 0.273988454],
  [0.26890375, 0.273950961],
  [0.268874152, 0.273913533],
  [0.268844611, 0.273876169],
  [0.268815125, 0.27383887],
  [0.268785696, 0.273801635],
  [0.268756322, 0.273764465],
  [0.268727004, 0.273727358],
  [0.268697741, 0.273690315],
  [0.268668533, 0.273653336],
  [0.268639381, 0.27361642],
  [0.268610284, 0.273579568],
  [0.268581241, 0.273542779],
  [0.268552253, 0.273506053],
  [0.26852332, 0.27346939],
  [0.268494441, 0.273432789],
  [0.268465616, 0.273396252],
  [0.268436846, 0.273359777],
  [0.268408129, 0.273323364],
  [0.268379467, 0.273287013],
  [0.268350858, 0.273250725],
  [0.268322302, 0.273214498],
  [0.2682938, 0.273178333],
  [0.268265351, 0.273142229],
  [0.268236956, 0.273106187],
  [0.268208613, 0.273070207],
  [0.268180323, 0.273034287],
  [0.268152086, 0.272998428],
  [0.268123901, 0.272962631],
  [0.268095769, 0.272926894],
  [0.268067689, 0.272891217],
  [0.268039661, 0.272855601],
  [0.268011685, 0.272820045],
  [0.267983761, 0.27278455],
  [0.267955889, 0.272749114],
  [0.267928069, 0.272713738],
  [0.267900299, 0.272678422],
  [0.267872581, 0.272643166],
  [0.267844915, 0.272607969],
  [0.267817299, 0.272572831],
  [0.267789734, 0.272537752],
  [0.26776222, 0.272502732],
  [0.267734757, 0.272467772],
  [0.267707344, 0.27243287],
  [0.267679982, 0.272398026],
  [0.26765267, 0.272363241],
  [0.267625408, 0.272328514],
  [0.267598196, 0.272293846],
  [0.267571033, 0.272259235],
  [0.267543921, 0.272224682],
  [0.267516858, 0.272190188],
  [0.267489845, 0.27215575],
  [0.26746288, 0.272121371],
  [0.267435966, 0.272087048],
  [0.2674091, 0.272052783],
  [0.267382283, 0.272018575],
  [0.267355515, 0.271984424],
  [0.267328796, 0.271950329],
  [0.267302125, 0.271916292],
  [0.267275503, 0.271882311],
  [0.267248929, 0.271848386],
  [0.267222403, 0.271814518],
  [0.267195925, 0.271780706],
  [0.267169495, 0.271746949],
  [0.267143113, 0.271713249],
  [0.267116779, 0.271679605],
  [0.267090493, 0.271646016],
  [0.267064253, 0.271612482],
  [0.267038062, 0.271579004],
  [0.267011917, 0.271545582],
  [0.266985819, 0.271512214],
  [0.266959769, 0.271478901],
  [0.266933765, 0.271445644],
  [0.266907808, 0.271412441],
  [0.266881898, 0.271379292],
  [0.266856034, 0.271346198],
  [0.266830216, 0.271313159],
  [0.266804445, 0.271280174],
  [0.26677872, 0.271247242],
  [0.266753041, 0.271214365],
  [0.266727407, 0.271181542],
  [0.26670182, 0.271148772],
  [0.266676278, 0.271116056],
  [0.266650782, 0.271083394],
  [0.266625331, 0.271050785],
  [0.266599926, 0.271018229],
  [0.266574565, 0.270985726],
  [0.26654925, 0.270953276],
  [0.26652398, 0.270920879],
  [0.266498755, 0.270888535],
  [0.266473574, 0.270856244],
  [0.266448438, 0.270824005],
  [0.266423347, 0.270791818],
  [0.2663983, 0.270759684],
  [0.266373297, 0.270727601],
  [0.266348338, 0.270695571],
  [0.266323424, 0.270663593],
  [0.266298553, 0.270631667],
  [0.266273726, 0.270599792],
  [0.266248943, 0.270567969],
  [0.266224204, 0.270536197],
  [0.266199508, 0.270504476],
  [0.266174856, 0.270472807],
  [0.266150247, 0.270441189],
  [0.266125681, 0.270409622],
  [0.266101158, 0.270378106],
  [0.266076678, 0.27034664],
  [0.266052241, 0.270315225],
  [0.266027847, 0.270283861],
  [0.266003495, 0.270252547],
  [0.265979186, 0.270221283],
  [0.26595492, 0.270190069],
  [0.265930696, 0.270158906],
  [0.265906513, 0.270127792],
  [0.265882374, 0.270096728],
  [0.265858276, 0.270065714],
  [0.26583422, 0.27003475],
  [0.265810206, 0.270003835],
  [0.265786233, 0.269972969],
  [0.265762302, 0.269942153],
  [0.265738413, 0.269911386],
  [0.265714565, 0.269880668],
  [0.265690758, 0.269849999],
  [0.265666993, 0.269819379],
  [0.265643269, 0.269788807],
  [0.265619585, 0.269758284],
  [0.265595943, 0.269727809],
  [0.265572341, 0.269697383],
  [0.26554878, 0.269667006],
  [0.26552526, 0.269636676],
  [0.26550178, 0.269606394],
  [0.26547834, 0.269576161],
  [0.265454941, 0.269545975],
  [0.265431582, 0.269515837],
  [0.265408263, 0.269485747],
  [0.265384984, 0.269455704],
  [0.265361745, 0.269425708],
  [0.265338545, 0.26939576],
  [0.265315386, 0.269365859],
  [0.265292266, 0.269336005],
  [0.265269185, 0.269306199],
  [0.265246144, 0.269276439],
  [0.265223142, 0.269246726],
  [0.26520018, 0.269217059],
  [0.265177256, 0.269187439],
  [0.265154372, 0.269157866],
  [0.265131526, 0.269128339],
  [0.265108719, 0.269098858],
  [0.265085952, 0.269069424],
  [0.265063222, 0.269040035],
  [0.265040532, 0.269010693],
  [0.265017879, 0.268981396],
  [0.264995265, 0.268952145],
  [0.26497269, 0.26892294],
  [0.264950152, 0.268893781],
  [0.264927653, 0.268864666],
  [0.264905191, 0.268835598],
  [0.264882768, 0.268806574],
  [0.264860382, 0.268777596],
  [0.264838034, 0.268748662],
  [0.264815724, 0.268719774],
  [0.264793451, 0.268690931],
  [0.264771216, 0.268662132],
  [0.264749018, 0.268633378],
  [0.264726857, 0.268604668],
  [0.264704733, 0.268576003],
  [0.264682647, 0.268547383],
  [0.264660598, 0.268518806],
  [0.264638585, 0.268490274],
  [0.264616609, 0.268461786],
  [0.26459467, 0.268433342],
  [0.264572768, 0.268404942],
  [0.264550902, 0.268376585],
  [0.264529073, 0.268348272],
  [0.26450728, 0.268320003],
  [0.264485523, 0.268291778],
  [0.264463803, 0.268263596],
  [0.264442118, 0.268235457],
  [0.26442047, 0.268207361],
  [0.264398858, 0.268179308],
  [0.264377281, 0.268151299],
  [0.264355741, 0.268123332],
  [0.264334236, 0.268095409],
  [0.264312766, 0.268067528],
  [0.264291332, 0.268039689],
  [0.264269934, 0.268011894],
  [0.264248571, 0.26798414],
  [0.264227243, 0.26795643],
  [0.264205951, 0.267928761],
  [0.264184693, 0.267901135],
  [0.264163471, 0.26787355],
  [0.264142283, 0.267846008],
  [0.26412113, 0.267818508],
  [0.264100013, 0.267791049],
  [0.264078929, 0.267763633],
  [0.264057881, 0.267736258],
  [0.264036867, 0.267708924],
  [0.264015887, 0.267681632],
  [0.263994942, 0.267654382],
  [0.263974031, 0.267627172],
  [0.263953154, 0.267600004],
  [0.263932312, 0.267572877],
  [0.263911503, 0.267545791],
  [0.263890729, 0.267518746],
  [0.263869988, 0.267491742],
  [0.263849281, 0.267464779],
  [0.263828608, 0.267437856],
  [0.263807968, 0.267410974],
  [0.263787362, 0.267384132],
  [0.26376679, 0.267357331],
  [0.263746251, 0.26733057],
  [0.263725745, 0.26730385],
  [0.263705273, 0.267277169],
  [0.263684834, 0.267250529],
  [0.263664427, 0.267223929],
  [0.263644054, 0.267197368],
  [0.263623714, 0.267170848],
  [0.263603407, 0.267144367],
  [0.263583132, 0.267117926],
  [0.263562891, 0.267091524],
  [0.263542682, 0.267065162],
  [0.263522505, 0.267038839],
  [0.263502361, 0.267012556],
  [0.263482249, 0.266986312],
  [0.26346217, 0.266960106],
  [0.263442123, 0.26693394],
  [0.263422108, 0.266907813],
  [0.263402126, 0.266881725],
  [0.263382175, 0.266855676],
  [0.263362256, 0.266829666],
  [0.26334237, 0.266803694],
  [0.263322515, 0.26677776],
  [0.263302691, 0.266751865],
  [0.2632829, 0.266726009],
  [0.26326314, 0.266700191],
  [0.263243412, 0.266674411],
  [0.263223715, 0.266648669],
  [0.263204049, 0.266622966],
  [0.263184415, 0.2665973],
  [0.263164812, 0.266571673],
  [0.263145241, 0.266546083],
  [0.2631257, 0.266520531],
  [0.26310619, 0.266495017],
  [0.263086712, 0.26646954],
  [0.263067264, 0.266444101],
  [0.263047847, 0.266418699],
  [0.263028461, 0.266393334],
  [0.263009105, 0.266368007],
  [0.26298978, 0.266342718],
  [0.262970486, 0.266317465],
  [0.262951222, 0.266292249],
  [0.262931989, 0.266267071],
  [0.262912786, 0.266241929],
  [0.262893613, 0.266216824],
  [0.26287447, 0.266191756],
  [0.262855358, 0.266166724],
  [0.262836275, 0.266141729],
  [0.262817223, 0.266116771],
  [0.2627982, 0.266091849],
  [0.262779208, 0.266066963],
  [0.262760245, 0.266042114],
  [0.262741312, 0.266017301],
  [0.262722408, 0.265992524],
  [0.262703534, 0.265967783],
  [0.26268469, 0.265943079],
  [0.262665875, 0.26591841],
  [0.262647089, 0.265893777],
  [0.262628333, 0.265869179],
  [0.262609606, 0.265844618],
  [0.262590908, 0.265820092],
  [0.26257224, 0.265795602],
  [0.2625536, 0.265771147],
  [0.26253499, 0.265746727],
  [0.262516408, 0.265722343],
  [0.262497855, 0.265697994],
  [0.262479331, 0.26567368],
  [0.262460836, 0.265649402],
  [0.26244237, 0.265625158],
  [0.262423932, 0.26560095],
  [0.262405522, 0.265576776],
  [0.262387141, 0.265552637],
  [0.262368789, 0.265528533],
  [0.262350465, 0.265504463],
  [0.262332169, 0.265480429],
  [0.262313901, 0.265456428],
  [0.262295662, 0.265432462],
  [0.262277451, 0.265408531],
  [0.262259267, 0.265384634],
  [0.262241112, 0.265360771],
  [0.262222985, 0.265336942],
  [0.262204885, 0.265313148],
  [0.262186813, 0.265289387],
  [0.26216877, 0.265265661],
  [0.262150753, 0.265241968],
  [0.262132765, 0.26521831],
  [0.262114803, 0.265194685],
  [0.26209687, 0.265171093],
  [0.262078963, 0.265147536],
  [0.262061085, 0.265124012],
  [0.262043233, 0.265100521],
  [0.262025409, 0.265077064],
  [0.262007612, 0.26505364],
  [0.261989842, 0.26503025],
  [0.261972099, 0.265006893],
  [0.261954383, 0.264983569],
  [0.261936694, 0.264960278],
  [0.261919032, 0.26493702],
  [0.261901397, 0.264913795],
  [0.261883788, 0.264890603],
  [0.261866207, 0.264867443],
  [0.261848652, 0.264844317],
  [0.261831123, 0.264821223],
  [0.261813621, 0.264798162],
  [0.261796146, 0.264775133],
  [0.261778697, 0.264752137],
  [0.261761274, 0.264729173],
  [0.261743878, 0.264706242],
  [0.261726508, 0.264683343],
  [0.261709164, 0.264660476],
  [0.261691846, 0.264637641],
  [0.261674554, 0.264614839],
  [0.261657289, 0.264592068],
  [0.261640049, 0.26456933],
  [0.261622835, 0.264546623],
  [0.261605647, 0.264523948],
  [0.261588485, 0.264501305],
  [0.261571349, 0.264478694],
  [0.261554238, 0.264456115],
  [0.261537153, 0.264433567],
  [0.261520094, 0.26441105],
  [0.26150306, 0.264388565],
  [0.261486052, 0.264366111],
  [0.261469069, 0.264343689],
  [0.261452111, 0.264321298],
  [0.261435179, 0.264298938],
  [0.261418271, 0.26427661],
  [0.26140139, 0.264254312],
  [0.261384533, 0.264232046],
  [0.261367701, 0.26420981],
  [0.261350894, 0.264187606],
  [0.261334113, 0.264165432],
  [0.261317356, 0.264143289],
  [0.261300624, 0.264121177],
  [0.261283917, 0.264099095],
  [0.261267235, 0.264077044],
  [0.261250577, 0.264055024],
  [0.261233944, 0.264033034],
  [0.261217336, 0.264011074],
  [0.261200752, 0.263989145],
  [0.261184193, 0.263967246],
  [0.261167658, 0.263945378],
  [0.261151148, 0.263923539],
  [0.261134662, 0.263901731],
  [0.2611182, 0.263879952],
  [0.261101763, 0.263858204],
  [0.26108535, 0.263836486],
  [0.26106896, 0.263814797],
  [0.261052595, 0.263793139],
  [0.261036254, 0.26377151],
  [0.261019937, 0.263749911],
  [0.261003644, 0.263728341],
  [0.260987375, 0.263706801],
  [0.26097113, 0.263685291],
  [0.260954908, 0.26366381],
  [0.26093871, 0.263642359],
  [0.260922536, 0.263620937],
  [0.260906386, 0.263599544],
  [0.260890259, 0.26357818],
  [0.260874155, 0.263556846],
  [0.260858075, 0.263535541],
  [0.260842019, 0.263514264],
  [0.260825986, 0.263493017],
  [0.260809976, 0.263471799],
  [0.260793989, 0.26345061],
  [0.260778026, 0.263429449],
  [0.260762086, 0.263408318],
  [0.260746169, 0.263387215],
  [0.260730276, 0.263366141],
  [0.260714405, 0.263345095],
  [0.260698557, 0.263324078],
  [0.260682732, 0.263303089],
  [0.26066693, 0.263282129],
  [0.260651151, 0.263261198],
  [0.260635395, 0.263240294],
  [0.260619662, 0.263219419],
  [0.260603951, 0.263198573],
  [0.260588263, 0.263177754],
  [0.260572597, 0.263156963],
  [0.260556954, 0.263136201],
  [0.260541334, 0.263115467],
  [0.260525736, 0.26309476],
  [0.260510161, 0.263074082],
  [0.260494608, 0.263053431],
  [0.260479077, 0.263032808],
  [0.260463568, 0.263012213],
  [0.260448082, 0.262991646],
  [0.260432618, 0.262971106],
  [0.260417176, 0.262950594],
  [0.260401756, 0.262930109],
  [0.260386359, 0.262909652],
  [0.260370983, 0.262889222],
  [0.260355629, 0.26286882],
  [0.260340297, 0.262848445],
  [0.260324988, 0.262828097],
  [0.260309699, 0.262807776],
  [0.260294433, 0.262787483],
  [0.260279189, 0.262767217],
  [0.260263966, 0.262746978],
  [0.260248764, 0.262726765],
  [0.260233585, 0.26270658],
  [0.260218427, 0.262686422],
  [0.26020329, 0.26266629],
  [0.260188175, 0.262646186],
  [0.260173082, 0.262626108],
  [0.26015801, 0.262606057],
  [0.260142959, 0.262586032],
  [0.260127929, 0.262566034],
  [0.260112921, 0.262546063],
  [0.260097934, 0.262526118],
  [0.260082968, 0.2625062],
  [0.260068023, 0.262486308],
  [0.260053099, 0.262466442],
  [0.260038197, 0.262446602],
  [0.260023315, 0.262426789],
  [0.260008454, 0.262407002],
  [0.259993614, 0.262387242],
  [0.259978796, 0.262367507],
  [0.259963997, 0.262347798],
  [0.25994922, 0.262328116],
  [0.259934464, 0.262308459],
  [0.259919728, 0.262288828],
  [0.259905012, 0.262269223],
  [0.259890318, 0.262249644],
  [0.259875644, 0.262230091],
  [0.25986099, 0.262210563],
  [0.259846357, 0.262191061],
  [0.259831745, 0.262171585],
  [0.259817153, 0.262152134],
  [0.259802581, 0.262132709],
  [0.25978803, 0.262113309],
  [0.259773498, 0.262093934],
  [0.259758988, 0.262074585],
  [0.259744497, 0.262055262],
  [0.259730026, 0.262035963],
  [0.259715576, 0.26201669],
  [0.259701146, 0.261997442],
  [0.259686735, 0.261978219],
  [0.259672345, 0.261959021],
  [0.259657975, 0.261939848],
  [0.259643624, 0.2619207],
  [0.259629294, 0.261901578],
  [0.259614983, 0.26188248],
  [0.259600692, 0.261863407],
  [0.259586421, 0.261844358],
  [0.25957217, 0.261825335],
  [0.259557938, 0.261806336],
  [0.259543726, 0.261787362],
  [0.259529534, 0.261768412],
  [0.259515361, 0.261749487],
  [0.259501208, 0.261730587],
  [0.259487074, 0.261711711],
  [0.259472959, 0.261692859],
  [0.259458864, 0.261674032],
  [0.259444789, 0.261655229],
  [0.259430732, 0.261636451],
  [0.259416695, 0.261617697],
  [0.259402678, 0.261598967],
  [0.259388679, 0.261580261],
  [0.2593747, 0.261561579],
  [0.25936074, 0.261542922],
  [0.259346799, 0.261524288],
  [0.259332877, 0.261505678],
  [0.259318974, 0.261487093],
  [0.25930509, 0.261468531],
  [0.259291225, 0.261449993],
  [0.259277378, 0.261431479],
  [0.259263551, 0.261412989],
  [0.259249743, 0.261394522],
  [0.259235953, 0.261376079],
  [0.259222182, 0.26135766],
  [0.25920843, 0.261339264],
  [0.259194697, 0.261320892],
  [0.259180982, 0.261302543],
  [0.259167286, 0.261284218],
  [0.259153608, 0.261265916],
  [0.259139949, 0.261247638],
  [0.259126309, 0.261229383],
  [0.259112687, 0.261211151],
  [0.259099083, 0.261192942],
  [0.259085498, 0.261174757],
  [0.259071931, 0.261156595],
  [0.259058383, 0.261138456],
  [0.259044853, 0.26112034],
  [0.259031341, 0.261102247],
  [0.259017847, 0.261084177],
  [0.259004372, 0.26106613],
  [0.258990914, 0.261048106],
  [0.258977475, 0.261030105],
  [0.258964054, 0.261012126],
  [0.258950651, 0.260994171],
  [0.258937265, 0.260976238],
  [0.258923898, 0.260958328],
  [0.258910549, 0.26094044],
  [0.258897218, 0.260922575],
  [0.258883904, 0.260904733],
  [0.258870609, 0.260886913],
  [0.258857331, 0.260869116],
  [0.258844071, 0.260851341],
  [0.258830829, 0.260833589],
  [0.258817604, 0.260815859],
  [0.258804397, 0.260798151],
  [0.258791208, 0.260780466],
  [0.258778036, 0.260762803],
  [0.258764882, 0.260745162],
  [0.258751746, 0.260727543],
  [0.258738626, 0.260709946],
  [0.258725525, 0.260692372],
  [0.258712441, 0.260674819],
  [0.258699374, 0.260657289],
  [0.258686324, 0.26063978],
  [0.258673292, 0.260622294],
  [0.258660277, 0.260604829],
  [0.25864728, 0.260587386],
  [0.258634299, 0.260569965],
  [0.258621336, 0.260552566],
  [0.25860839, 0.260535188],
  [0.258595461, 0.260517832],
  [0.258582549, 0.260500498],
  [0.258569655, 0.260483186],
  [0.258556777, 0.260465895],
  [0.258543916, 0.260448625],
  [0.258531072, 0.260431377],
  [0.258518246, 0.260414151],
  [0.258505436, 0.260396946],
  [0.258492643, 0.260379762],
  [0.258479866, 0.2603626],
  [0.258467107, 0.260345459],
  [0.258454364, 0.260328339],
  [0.258441638, 0.260311241],
  [0.258428929, 0.260294163],
  [0.258416237, 0.260277107],
  [0.258403561, 0.260260072],
  [0.258390901, 0.260243058],
  [0.258378259, 0.260226065],
  [0.258365633, 0.260209093],
  [0.258353023, 0.260192143],
  [0.25834043, 0.260175213],
  [0.258327853, 0.260158303],
  [0.258315293, 0.260141415],
  [0.258302749, 0.260124548],
  [0.258290221, 0.260107701],
  [0.25827771, 0.260090875],
  [0.258265215, 0.26007407],
  [0.258252737, 0.260057286],
  [0.258240274, 0.260040522],
  [0.258227828, 0.260023778],
  [0.258215398, 0.260007056],
  [0.258202984, 0.259990353],
  [0.258190586, 0.259973672],
  [0.258178205, 0.259957011],
  [0.258165839, 0.25994037],
  [0.25815349, 0.259923749],
  [0.258141156, 0.259907149],
  [0.258128838, 0.259890569],
  [0.258116537, 0.25987401],
  [0.258104251, 0.259857471],
  [0.258091981, 0.259840952],
  [0.258079727, 0.259824453],
  [0.258067489, 0.259807974],
  [0.258055266, 0.259791515],
  [0.258043059, 0.259775077],
  [0.258030868, 0.259758658],
  [0.258018693, 0.259742259],
  [0.258006534, 0.259725881],
  [0.25799439, 0.259709522],
  [0.257982261, 0.259693183],
  [0.257970148, 0.259676864],
  [0.257958051, 0.259660565],
  [0.257945969, 0.259644286],
  [0.257933903, 0.259628026],
  [0.257921852, 0.259611786],
  [0.257909817, 0.259595566],
  [0.257897797, 0.259579365],
  [0.257885793, 0.259563184],
  [0.257873803, 0.259547023],
  [0.257861829, 0.259530881],
  [0.257849871, 0.259514758],
  [0.257837928, 0.259498655],
  [0.257825999, 0.259482572],
  [0.257814087, 0.259466508],
  [0.257802189, 0.259450463],
  [0.257790306, 0.259434438],
  [0.257778439, 0.259418432],
  [0.257766587, 0.259402445],
  [0.257754749, 0.259386477],
  [0.257742927, 0.259370529],
  [0.25773112, 0.2593546],
  [0.257719328, 0.25933869],
  [0.25770755, 0.259322799],
  [0.257695788, 0.259306927],
  [0.257684041, 0.259291074],
  [0.257672308, 0.25927524],
  [0.25766059, 0.259259425],
  [0.257648887, 0.259243629],
  [0.257637199, 0.259227852],
  [0.257625526, 0.259212094],
  [0.257613867, 0.259196355],
  [0.257602223, 0.259180634],
  [0.257590594, 0.259164933],
  [0.25757898, 0.25914925],
  [0.25756738, 0.259133585],
  [0.257555794, 0.25911794],
  [0.257544224, 0.259102313],
  [0.257532667, 0.259086704],
  [0.257521126, 0.259071115],
  [0.257509598, 0.259055543],
  [0.257498086, 0.259039991],
  [0.257486588, 0.259024457],
  [0.257475104, 0.259008941],
  [0.257463634, 0.258993443],
  [0.257452179, 0.258977965],
  [0.257440738, 0.258962504],
  [0.257429312, 0.258947062],
  [0.2574179, 0.258931638],
  [0.257406502, 0.258916232],
  [0.257395118, 0.258900845],
  [0.257383749, 0.258885475],
  [0.257372394, 0.258870124],
  [0.257361052, 0.258854791],
  [0.257349725, 0.258839476],
  [0.257338413, 0.25882418],
  [0.257327114, 0.258808901],
  [0.257315829, 0.25879364],
  [0.257304558, 0.258778397],
  [0.257293302, 0.258763173],
  [0.257282059, 0.258747966],
  [0.25727083, 0.258732777],
  [0.257259615, 0.258717606],
  [0.257248414, 0.258702453],
  [0.257237227, 0.258687317],
  [0.257226054, 0.258672199],
  [0.257214895, 0.2586571],
  [0.257203749, 0.258642017],
  [0.257192617, 0.258626953],
  [0.257181499, 0.258611906],
  [0.257170395, 0.258596877],
  [0.257159305, 0.258581865],
  [0.257148228, 0.258566871],
  [0.257137164, 0.258551894],
  [0.257126115, 0.258536935],
  [0.257115079, 0.258521994],
  [0.257104056, 0.25850707],
  [0.257093047, 0.258492163],
  [0.257082052, 0.258477274],
  [0.25707107, 0.258462402],
  [0.257060102, 0.258447547],
  [0.257049147, 0.25843271],
  [0.257038206, 0.258417889],
  [0.257027277, 0.258403087],
  [0.257016363, 0.258388301],
  [0.257005462, 0.258373532],
  [0.256994574, 0.258358781],
  [0.256983699, 0.258344047],
  [0.256972838, 0.25832933],
  [0.25696199, 0.25831463],
  [0.256951155, 0.258299947],
  [0.256940333, 0.258285281],
  [0.256929525, 0.258270632],
  [0.25691873, 0.258256],
  [0.256907948, 0.258241385],
  [0.256897179, 0.258226787],
  [0.256886423, 0.258212205],
  [0.25687568, 0.258197641],
  [0.25686495, 0.258183093],
  [0.256854234, 0.258168562],
  [0.25684353, 0.258154048],
  [0.25683284, 0.258139551],
  [0.256822162, 0.25812507],
  [0.256811497, 0.258110606],
  [0.256800845, 0.258096158],
  [0.256790206, 0.258081727],
  [0.25677958, 0.258067313],
  [0.256768967, 0.258052916],
  [0.256758367, 0.258038535],
  [0.256747779, 0.25802417],
  [0.256737205, 0.258009822],
  [0.256726643, 0.25799549],
  [0.256716094, 0.257981175],
  [0.256705557, 0.257966876],
  [0.256695033, 0.257952593],
  [0.256684522, 0.257938327],
  [0.256674024, 0.257924077],
  [0.256663538, 0.257909844],
  [0.256653064, 0.257895627],
  [0.256642604, 0.257881426],
  [0.256632156, 0.257867241],
  [0.25662172, 0.257853072],
  [0.256611297, 0.257838919],
  [0.256600887, 0.257824783],
  [0.256590489, 0.257810663],
  [0.256580103, 0.257796559],
  [0.25656973, 0.25778247],
  [0.256559369, 0.257768398],
  [0.256549021, 0.257754342],
  [0.256538685, 0.257740302],
  [0.256528361, 0.257726278],
  [0.25651805, 0.257712269],
  [0.256507751, 0.257698277],
  [0.256497464, 0.2576843],
  [0.256487189, 0.25767034],  // ...
  [0.256476927, 0.257656395], // CCT = 19,980
  [0.256466677, 0.257642466], // CCT = 19,990
  [0.256456439, 0.257628552], // CCT = 20,000
];


function loadMatFromArray(array) {
  // Load a matrix from an array, and transpose
  var mat = new THREE.Matrix3();
  mat.fromArray(array);
  mat.transpose();
  return mat;
}

export function cctToXyz(cct) {
  const min_cct = 1000;
  const max_cct = 20000;
  const cct_step = 10;
  if (cct < min_cct || cct > max_cct) {
    console.log('invalid CCT value');
    return [1, 1, 1];
  }

  const index = Math.round((cct - min_cct) / cct_step);
  const tristimulus = cctToXyLut[index];
  const chroma = [
      tristimulus[0] / tristimulus[1],
      1.0,
      (1.0 - tristimulus[0] - tristimulus[1]) / tristimulus[1]
  ];
  return chroma;
}

export function getCatMatrix(src_xyz, dst_xyz) {
  var M = new THREE.Matrix3();
  var bMat = loadMatFromArray(bradford);
  var bMatInv = loadMatFromArray(bradfordInv);

  var src_working = new THREE.Vector3();
  src_working.fromArray(src_xyz);
  var dst_working = new THREE.Vector3();
  dst_working.fromArray(dst_xyz);
  src_working.applyMatrix3(bMat);
  dst_working.applyMatrix3(bMat);
  dst_working.divide(src_working);

  var diagMat = new THREE.Matrix3();
  diagMat.fromArray([
    dst_working.x, 0, 0,
    0, dst_working.y, 0,
    0, 0, dst_working.z
  ]);

  M.copy(bMatInv);
  M.multiply(diagMat);
  M.multiply(bMat);

  return M;
}

export function getWhiteBalanceMatrix(cct_src, cct_dst=6500) {
  var xyzToLsrgbMat = loadMatFromArray(xyzToLsrgb);
  var lsrgbToXyzMat = loadMatFromArray(lsrgbToXyz);

  const chroma_src = cctToXyz(cct_src);
  const chroma_dst = cctToXyz(cct_dst);
  const cat = getCatMatrix(chroma_src, chroma_dst);

  var M = new THREE.Matrix3();
  M.copy(xyzToLsrgbMat);
  M.multiply(cat);
  M.multiply(lsrgbToXyzMat);

  return M;
}
